import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from 'react'
import {buildImageObj} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'
import BlockContent from '../sanity/block-content'
import { Container } from '../container'
import AuthorList from './author-list'

import styles from './blog-post.module.css'

function BlogPost (props) {
  const {_rawBody, authors, categories, title, mainImage, publishedAt} = props
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.headerWrapper}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{title}</h1>
          {props._rawExcerpt && (
            <div className={styles.excerpt}>
              <BlockContent blocks={props._rawExcerpt} />
            </div>
          )}
        </div>
        <aside className={styles.metaContent}>
        
            {publishedAt && (
                <div className={styles.publishedAt}>
                  Publisert:
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? distanceInWords(new Date(publishedAt), new Date())
                    : format(new Date(publishedAt), ' DD. MM YYYY')}
                </div>
              )}
        </aside>
        </div>
      </Container>
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            {_rawBody && <BlockContent blocks={_rawBody} />}
          </div>
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
